import logo from './logo.svg';
import './App.css';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Tabs, Button, Spin } from 'antd';
import React, { useState, } from 'react';


function Test() {

  //文件转换
  const [upLoadFileId, setUpLoadFileId] = useState('fdef37c6-00d7-4e4e-8221-8a20f63caaee');
  const [upLoadFileName, setUpLoadFileName] = useState('2222.pdf')

  //转换后的文件Id
  const [converResutFileId, setConverResutFileId] = useState('');

  //合并后的文件Id
  const [mergeResutFileId, setMergeResutFileId] = useState('');

  //文件合并
  const [fileList, setFileList] = useState([]);
  const urlList = [];

  const [loading, setLoading] = useState(false);

  const { Dragger } = Upload;



  //文件转换Tab
  const converTab = {
    name: 'file',
    method: 'POST',
    multiple: false,
    action: 'api/file/upload',
    onChange(info) {
      console.log(info);
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        const uploadStatus = info.file.response.code;
        if (uploadStatus === 'OK') {
          message.success(`${info.file.name} file uploaded successfully.`);
          setUpLoadFileId(info.file.response.object)
          setUpLoadFileName(info.file.name)
          console.log(upLoadFileId);
        } else {
          message.error(`${info.file.name} file upload failed. ERROR ${info.file.response.msg}`);

        }
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  //文件转换方法
  const convert = async () => {
    const requestOptions = {
      method: 'POST', // 设置请求方法为POST
      headers: {
        // 设置请求的头部信息，例如内容类型为JSON
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'dId': upLoadFileId,
        'fileName': upLoadFileName
      })
    };
    try {
      setLoading(true)
      const response = await fetch('api/pdf/pdf2word', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const code = data.code;
      if (code === 'OK') {
        setConverResutFileId(`api/file/download/${data.object}`)
        message.success(`转换成功`);
      } else {
        message.success(`转换失败 原因: ${data.msg} .`);
      }
      console.log(data);
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setLoading(false)
    }
  };


  const mergeprops = {
    name: 'file',
    multiple: true,
    action: 'api/file/upload',
    onChange(info) {
      const { status } = info.file;
      console.log(info);
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setFileList([...fileList,`${info.file.response.object}/${info.file.name}`])
        console.log(`fileList is ${fileList}`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    onRemove: (file) => {
      console.log(`remove is ${fileList}`);
      setFileList((currentFileList) => {
        
        const index = currentFileList.indexOf(`${file.response.object}/${file.name}`);
        const newFileList = currentFileList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
      console.log(`remove is ${fileList}`);
    },
  };


  //文件合并方法
  const merge = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(fileList)
    };
    try {
      setLoading(true)
      const response = await fetch('api/pdf/merge', requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const code = data.code;
      if (code === 'OK') {
        setMergeResutFileId(`api/file/download/${data.object}`)
        message.success(`合并成功`);
      } else {
        message.success(`合并失败 原因: ${data.msg} .`);
      }
      console.log(data);
    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setLoading(false)
    }
  };

  const { TabPane } = Tabs;
  return (
    <Spin tip="转换中" size="large" spinning={loading}>
      <div style={{ textAlign: 'center', paddingRight: '80px', paddingLeft: '80px', paddingTop: '50px' }}>
        <Tabs defaultActiveKey="1" type='card'>
          <TabPane tab="PDF转Word" key="1">
            <div>
              <Dragger {...converTab} accept='.pdf' maxCount={1}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p >
                <p className="ant-upload-text">点击或拖拽到这个区域上传文件</p >
              </Dragger>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <Button type="primary" block onClick={() => convert()}>
                开始转换
              </Button>
            </div>
            <div style={{ textAlign: 'left' }}>
              {converResutFileId && (
                <p>
                  <a href={converResutFileId} download="file.txt">点击下载文件</a>
                </p>
              )}
            </div>
          </TabPane>
          <TabPane tab="PDF合并" key="2">
            <div>
              <Dragger {...mergeprops} accept='.pdf'>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p >
                <p className="ant-upload-text">点击或拖拽到这个区域上传文件</p >
              </Dragger>
            </div>
            <div style={{ paddingTop: '20px' }}>
              <Button type="primary" block onClick={() => merge()}>
                文件合并
              </Button>
            </div>
            <div style={{ textAlign: 'left' }}>
              {mergeResutFileId && (
                <p>
                  <a href={mergeResutFileId} download="file.txt">点击下载文件</a>
                </p>
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
}

export default Test;